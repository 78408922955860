import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import {
    ApolloClient,
    InMemoryCache,
    ApolloLink,
    HttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLClientNames } from 'lib/types';
import { getAuth } from 'firebase/auth';

// auth
const authLink = setContext((_, context) => {
    const auth = getAuth();

    if (auth.currentUser) {
        return auth.currentUser?.getIdToken().then((token) => {
            return {
                ...context,
                headers: {
                    ...context.headers,
                    authorization: token ? `Bearer ${token}` : '',
                    'Apollo-Require-Preflight': 'true',
                },
            };
        });
    }

    return {
        ...context,
        headers: {
            ...context.headers,
            'Apollo-Require-Preflight': 'true',
        },
    };
});

// routes
const uploadLink = authLink.concat(
    createUploadLink({
        uri: process.env.REACT_APP_TELEHEALTH_API_URL || '/graphql',
    }),
);

const batchedLink = authLink.concat(
    new BatchHttpLink({
        uri: process.env.REACT_APP_TELEHEALTH_API_URL || '/graphql',
        batchInterval: 20, // Wait no more than 20ms after first batched operation
    }),
);

const telehealthLink = ApolloLink.split(
    (operation) =>
        operation.getContext().clientName === GraphQLClientNames.batch,
    batchedLink,
    uploadLink,
);

const creditCardsLink = new HttpLink({
    uri: process.env.REACT_APP_CREDIT_CARDS_API_URL || '/credit-cards/graphql',
});

const promoCodesLink = new HttpLink({
    uri: process.env.REACT_APP_PROMO_CODES_API_URL || '/promo-codes/graphql',
});

const bondvetLink = new HttpLink({
    uri: process.env.REACT_APP_BONDVET_API_URL || '/graphql',
});

const bondvetAndTelehealthLink = ApolloLink.split(
    (operation) =>
        operation.getContext().clientName === GraphQLClientNames.bondvet,
    bondvetLink,
    telehealthLink,
);

const promoCodesAndBondVetLink = ApolloLink.split(
    (operation) =>
        operation.getContext().clientName === GraphQLClientNames.promoCodes,
    promoCodesLink,
    bondvetAndTelehealthLink,
);

const link = ApolloLink.split(
    (operation) =>
        operation.getContext().clientName === GraphQLClientNames.creditCards,
    creditCardsLink,
    promoCodesAndBondVetLink,
);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

export default client;
